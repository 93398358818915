  .fadeable-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  .fadeable-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  .fadeable-exit {
    opacity: 1;
  }
  .fadeable-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }

  .slide-enter {
    opacity: 0;
    transform: translate(0,-300px);
  }
  .slide-enter-active {
    opacity: 1;
    transform: translate(0,0px);
    transition: opacity 600ms ease, transform 500ms ease;
  }
  .slide-exit {
    opacity: 1;
    transform: translate(0,0px);
    transition: height 300ms ease;
  }
  .slide-exit-active {
    opacity: 0;
    transform: translate(0,-300px);
    transition: opacity 600ms ease, transform 400ms ease;
  }